import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import useAppearance from "./useAppearance";
import Constants from "../services/constants";
import { refreshMuiTheme } from "@local/theme";
// types
import type { PaletteMode } from "@mui/material";
import type { Appearance } from "../types/misc";

const getThemeModeFromAppearance = (appearance: Appearance, prefersDarkMode: boolean): PaletteMode => {
	if (appearance === Constants.Appearance.System) {
		return prefersDarkMode ? "dark" : "light";
	}
	return appearance;
};

const useThemeMode = (): PaletteMode => {
	const {appearance} = useAppearance();

	const mode = new URLSearchParams(window.location.search).get("mode") as Appearance | null;

	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

	const [themeMode, setThemeMode] = useState(getThemeModeFromAppearance(mode ?? appearance, prefersDarkMode));

	useEffect(() => {
		const newThemeMode = getThemeModeFromAppearance(mode ?? appearance, prefersDarkMode);
		if (newThemeMode !== themeMode) {
			setThemeMode(newThemeMode);
			refreshMuiTheme(newThemeMode);
		}
	}, [mode, appearance, prefersDarkMode]);

	return themeMode;
};

export default useThemeMode;
