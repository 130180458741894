import { Component } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Drawer, Divider, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
// cmp
import NavigationLogo from "./navigation-logo";
import NavigationDrawerMenu from "./navigation-drawer-menu";
import ConnectionFailedDialog from "./connection-failed-dialog";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import Accounts from "../../services/accounts";
import Settings from "../../services/settings";

class NavigationDrawer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			open: props.open,
			account: Accounts.getDefault(Settings.cluster.gupportWsUrl) || {}
		};

		this.handleAccountChanged = this.handleAccountChanged.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleExpertModeChanged = this.handleExpertModeChanged.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
	}

	componentDidMount() {
		Accounts.on("setDefault", this.handleAccountChanged);
		Settings.on("clusterChanged", this.handleAccountChanged);
	}

	componentDidUpdate(prevProps) {
		if (this.props.open !== prevProps.open) {
			this.setState({
				open: this.props.open
			});
		}
	}

	componentWillUnmount() {
		Accounts.off("setDefault", this.handleAccountChanged);
		Settings.off("clusterChanged", this.handleAccountChanged);
	}

	handleAccountChanged() {
		this.setState({
			account: Accounts.getDefault(Settings.cluster.gupportWsUrl) || {}
		});
	}

	handleClose() {
		this.setState({
			open: false
		}, () => {
			if (this.props.onOpenChanged) {
				this.props.onOpenChanged(this.state.open);
			}
		});
	}

	handleExpertModeChanged() {
		this.forceUpdate();
	}

	handleLogout() {
		Gupport.logout();
	}

	render() {
		const { t } = this.props;
		const account = Accounts.getDefault(Settings.cluster.gupportWsUrl);
		if (!account || !account.userId) {
			return <Navigate to="/login" />;
		}

		return (
			<Drawer
				variant={this.props.docked ? "persistent" : "temporary"}
				open={this.props.open}
				onClose={this.handleClose}
				PaperProps={{component: "nav", style: {width: this.props.width}}}
			>
				<div>{/* div needed here */}
					<ConnectionFailedDialog />
					<NavigationLogo onExpertModeChanged={this.handleExpertModeChanged} />
					<Divider />
					<List disablePadding={true}>
						<ListItem>
							<ListItemText
								primary={this.state.account.display}
								secondary={Gupport.user ? Gupport.user.username : null}
								primaryTypographyProps={{noWrap: true}}
								secondaryTypographyProps={{noWrap: true}}
							/>
							<ListItemSecondaryAction>
								<IconButton title={t("menu.logout")} edge="end" onClick={this.handleLogout}><Svg src="navigation/logout.svg" /></IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Divider />
						<NavigationDrawerMenu activeRoute={this.props.activeRoute} />
					</List>
				</div>
			</Drawer>
		);
	}

}

NavigationDrawer.defaultProps = {
	width: 300,
	onOpenChanged: null
};

NavigationDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	docked: PropTypes.bool.isRequired,
	activeRoute: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onOpenChanged: PropTypes.func,
	t: PropTypes.func.isRequired
};

export default withTranslation()(NavigationDrawer);
