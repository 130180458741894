// services
import { Storage } from "../services/storage";

const stateExpiresInterval = 1000 * 60 * 5; // 5 min

class StatesStorage {

	// key: "providers/{ID}/{KEY}";

	constructor(providerId, key) {
		this.key = "providers/{ID}/{KEY}".replace("{ID}", providerId).replace("{KEY}", key);
	}

	cleanUp() {
		const allStates = this.getStates();
		// cleanup expired and invalid states
		const now = Date.now();
		let index = 0;
		while (index < allStates.length) {
			const item = allStates[index];
			if (!item || (item.exp && item.exp < now)) {
				allStates.splice(index, 1);
			} else {
				index++;
			}
		}
		this.setStates(allStates);
	}

	getStates() {
		return Storage.get(this.key) ?? []; // TODO remove fallback
	}

	setStates(allStates) {
		Storage.set(this.key, allStates);
	}

	getState(state) {
		// cleanup expired and invalid states
		this.cleanUp();
		// find states
		const allStates = this.getStates();
		return allStates.find((item) => item.state === state);
	}

	setState(state) {
		state.state ||= globalThis.crypto.randomUUID();
		state.exp ||= Date.now() + stateExpiresInterval;
		const allStates = this.getStates();
		allStates.push(state);
		this.setStates(allStates);
	}

	removeState(state) {
		const allStates = this.getStates();
		let index = 0;
		while (index < allStates.length) {
			const item = allStates[index];
			if (item && item.state === state) {
				allStates.splice(index, 1);
			} else {
				index++;
			}
		}
		this.setStates(allStates);
	}

}

export default StatesStorage;
