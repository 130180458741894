import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemIcon
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import NavigationNestedMenu from "./navigation-nested-menu";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { Storage, StorageKeys } from "../../services/storage";
import { merge } from "../../services/utils";
import { icons } from "@local/theme";

const OTHER_MENU = {
	id: "other",
	label: "menu.other",
	nested: true,
	isVisible: () => (Storage.get(StorageKeys.expertMode)),
	singleDivider: true,
	items: [
		{
			id: "logs",
			label: "menu.logs",
			route: "/logs",
			icon: icons.Log
		},
		{
			id: "settings",
			label: "menu.settings",
			route: "/settings",
			icon: icons.Settings
		}
	]
} as const;

const MENU_ITEMS = [
	{
		id: "dashboard",
		label: "menu.dashboard",
		route: "/welcome",
		icon: icons.Dashboard,
		action: "getGatewayStats"
	},
	// {
	//   label: 'Solr Cluster',
	//   route: null,
	//   icon: icons.SolrCluster
	// },
	{
		id: "gateways",
		label: "menu.gateways",
		route: "/gateways",
		icon: "add/add-hub.svg"
	},
	{
		id: "users",
		label: "menu.users",
		route: "/users",
		icon: "navigation/users.svg"
	},
	// {
	//   label: 'Cloud Storage',
	//   route: null,
	//   icon: icons.CloudStorage
	// },
	{
		id: "server",
		label: "menu.playground",
		nested: true,
		items: [
			{
				id: "statistics",
				label: "menu.statistics",
				route: "/stats/gerver",
				icon: icons.GerverCluster,
				action: "getStats"
			},
			{
				id: "serverLog",
				label: "menu.serverLog",
				route: "/glogs",
				icon: icons.Log,
				action: "getLogs"
			},
			// {
			// 	id: "glient",
			// 	label: "menu.glient,
			// 	route: "/playground/glient",
			// 	icon: icons.Playground,
			// 	action: "getGlientPlayground"
			// },
			{
				id: "gupport",
				label: "menu.gupport",
				route: "/playground/gupport",
				icon: icons.Playground,
				action: "getGupportPlayground"
			},
			{
				id: "supportUser",
				label: "menu.supportUser",
				route: "/support/users",
				icon: "navigation/users.svg",
				levels: ["sup_user_read"]
			},
			{
				id: "teditor",
				label: "menu.teditor",
				route: "/teditor",
				icon: icons.TEditorIcon,
				action: "getMetadataEditor"
			},
			{
				id: "backup",
				label: "menu.backup",
				route: "/backups",
				icon: icons.GerverCluster,
				levels: ["backup_read"]
			}
		]
	},
	{
		id: "utilities",
		label: "menu.utilities",
		nested: true,
		singleDivider: true,
		items: [
			{
				id: "localization",
				label: "menu.localization",
				route: "/utilities/localization",
				icon: icons.Localization
			}
		]
	},
	OTHER_MENU,
	{
		id: "general-settings",
		label: "menu.generalSettings",
		route: "/general-settings",
		icon: icons.Settings
	},
	{
		id: "about",
		label: "menu.about",
		route: "/about",
		icon: "navigation/support.svg"
	}
] as const;

type Props = {
	activeRoute: string;
};

const NavigationDrawerMenu = (props: Props)	=> {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [ready, setReady] = useState(Gupport.ready);

	const shouldShow = (item) => {
		if (item !== undefined) {
			if (!item.levels && !item.action) {
				return true;
			}
			if (item.action && !Gupport[item.action]) {
				return false;
			}
			if (item.levels && !Gupport.hasAnyLevel(item.levels)) {
				return false;
			}
		}

		return true;
	};

	const handleGupportReady = () => {
		const activeItem = MENU_ITEMS.find((menuItem) => (menuItem.route === props.activeRoute));
		if (shouldShow(activeItem)) {
			setReady(true);
		} else {
			const menuItemShow = MENU_ITEMS.find((menuItem) => (shouldShow(menuItem)));
			navigate(menuItemShow.route);
		}
	};

	useEffect(() => {
		Gupport.on("ready", handleGupportReady);

		return () => {
			Gupport.off("ready", handleGupportReady);
		};
	}, []);

	const getFilteredMenuItems = (items) => items.filter((item) => {
		if (item.isVisible && !item.isVisible()) {
			return false;
		}
		if (!shouldShow(item)) {
			return false;
		}
		if (item.nested && !item.items.some((item) => (shouldShow(item)))) {
			return false;
		}
		return true;
	});

	const renderNestedMenu = (menuItems, nested, activeRoute) => {
		const baseStyle = nested ? { paddingLeft: "44px" } : {};

		return getFilteredMenuItems(menuItems).map((item, index) => {
			const color = (item.route === activeRoute) ? theme.palette.primary.main : theme.palette.text.secondary;
			const style = {...baseStyle, color: color};
			const icon = (typeof item.icon === "string")
				? <Svg src={item.icon} size={24} color={color} />
				: item.icon ? <item.icon htmlColor={color} /> : null;

			if (item.onSelected) {
				return (
					<ListItemButton key={index} style={style} onClick={item.onSelected}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItemButton>
				);
			}
			if (activeRoute === item.route) {
				return (
					<ListItem key={index} style={style}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItem>
				);
			}
			if (item.nested) {
				const nestedMenuItems = merge(item, { items: getFilteredMenuItems(item.items) });
				return (
					<NavigationNestedMenu
						key={index}
						item={nestedMenuItems}
						activeRoute={activeRoute}
						renderNestedMenu={renderNestedMenu}
					/>
				);
			}
			return (
				<Link key={index} to={item.route} className="no-decoration">
					<ListItemButton style={style}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(item.label)} />
					</ListItemButton>
				</Link>
			);
		});
	};

	if (!ready) {
		if (Storage.get(StorageKeys.expertMode)) {
			return renderNestedMenu([OTHER_MENU], true, props.activeRoute);
		}
		return null;
	}
	return renderNestedMenu(MENU_ITEMS, false, props.activeRoute);
};

NavigationDrawerMenu.propTypes = {
	activeRoute: PropTypes.string.isRequired
};

export default NavigationDrawerMenu;
