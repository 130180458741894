import { Route } from "react-router-dom";
import { stringify } from "qs";
import md5 from "blueimp-md5";
// services
import Account from "../services/accounts";
import Settings from "../services/settings";
// pages
import VerifyPage from "./user-password-verify-page";

class UserPassword {

	static storageKey(provider, key) {
		return `providers/${provider.id}/${key}`;
	}

	static routes(provider) {
		return (
			<Route path="verify" element={<VerifyPage provider={provider} redirectTo={Settings.publicUrl} />} />
		);
	}

	static login(provider, accountFor) {
		const params = {
			accountFor: accountFor
		};
		window.location = `${Settings.publicUrl}?${stringify(params)}#/providers/${provider.id}/verify`;
	}

	static logout(provider, account) {
		Account.remove(account);
	}

	static redirect(/*provider, searchParams*/) {
		return false;
	}

	static verify(provider, query, username, password) {
		return {
			providerId: provider.id,
			userId: username,
			username: username,
			display: username,
			password: md5(password),
			for: query.accountFor
		};
	}

	static gupportLogin(provider, account) {
		return {
			action: "login",
			username: account.username,
			password: account.password
		};
	}

	static glientLogin(provider, account) {
		return {
			action: "login",
			username: account.username,
			password: account.password
		};
	}

}

export default UserPassword;
