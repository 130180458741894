import deepmerge from "deepmerge";

export const merge = (...params: Array<object>): object => (Object.assign({}, ...params));

export const mergeDeep = <O1, O2>(obj1: O1, obj2: O2): O1 & O2 => (
	deepmerge<O1, O2>(obj1, obj2, {arrayMerge: (destinationArray, sourceArray) => (sourceArray)})
);

export const formatBytes = (bytes: number, decimals: number = 2): string => {
	if (!bytes) {
		return "0 Bytes";
	}
	const k = 1000;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] as const;
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i] as (typeof sizes)[number]}`;
};

export const compareObjects = (o1: object, o2: object): boolean => {
	for (const p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	for (const p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false;
			}
		}
	}
	return true;
};

const SORT_COLLATOR = new Intl.Collator([], {sensitivity: "base"});

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
 * @param {String} string1
 * @param {String} string2
 * @return {Number}
 */
export const sortAlphabetically = (string1: string, string2: string): number => (
	SORT_COLLATOR.compare(string1, string2)
);

export const BBCODE_BOLD_REGEX = /\[b](.+?)\[\/b]/;
