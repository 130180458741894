import StatesStorage from "./states-storage";
// services
import Settings from "../services/settings";
import Account from "../services/accounts";

const isRocBuildUrl = () => (window.location.origin === "https://rocbuild.blob.core.windows.net");
const getRefererUrl = () => (`${window.location.origin}${window.location.pathname}${window.location.search}`);

class OAuth2Cookie {

	static storageKey(provider, key) {
		return `providers/${provider.id}/${key}`;
	}

	static getLoginState(provider, state) {
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "loginStates"));
		return storage.getState(state);
	}

	static setLoginState(provider, state) {
		state.state ||= provider.state;
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "loginStates"));
		return storage.setState(state);
	}

	static removeLoginState(provider, state) {
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "loginStates"));
		storage.removeState(state);
	}

	static getLogoutState(provider, state) {
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "logoutStates"));
		return storage.getState(state);
	}

	static setLogoutState(provider, state) {
		state.state ||= provider.state;
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "logoutStates"));
		return storage.setState(state);
	}

	static removeLogoutState(provider, state) {
		const storage = new StatesStorage(OAuth2Cookie.storageKey(provider, "logoutStates"));
		storage.removeState(state);
	}

	static routes(/*provider*/) {
		return null;
	}

	static login(provider, accountFor) {
		const loginState = {
			accountFor: accountFor
		};
		OAuth2Cookie.setLoginState(provider, loginState);
		const params = new URLSearchParams({
			state: loginState.state,
			channel: Settings.channel
		});
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}
		let url = provider.redirectUrl;
		url = `${url.replace(/\/$/, "")}/${Settings.channel}/redirect`;
		url += url.includes("?") ? "&" : "?";
		url += params.toString();
		window.location.href = url;
	}

	static logout(provider, account) {
		const logoutState = {
			account: account
		};
		OAuth2Cookie.setLogoutState(provider, logoutState);
		const params = new URLSearchParams({
			state: logoutState.state,
			channel: Settings.channel
		});
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}
		let url = provider.revokeUrl;
		url = `${url.replace(/\/$/, "")}/${Settings.channel}/logout`;
		url += url.includes("?") ? "&" : "?";
		url += params.toString();
		window.location.href = url;
	}

	static refresh(provider, account, done) {
		const loginState = {
			accountFor: account.for
		};
		OAuth2Cookie.setLoginState(provider, loginState);
		const params = new URLSearchParams({
			state: loginState.state,
			channel: Settings.channel
		});
		let url = provider.redirectUrl;
		url = `${url.replace(/\/$/, "")}/${Settings.channel}/redirect`;
		url += url.includes("?") ? "&" : "?";
		url += params.toString();

		fetch(url, {credentials: "include"}).then((response) => {
			if (response.status >= 400) {
				done(new Error(`response code:${response.status}`));
			}
			return response.json();
		}).then((data) => {
			if (done) {
				const account = OAuth2Cookie.accountFromLoginState(provider, data);
				done(null, account);
			}
		}).catch((error) => {
			if (done)	{
				done(error);
			}
		});
	}

	static accountFromLoginState(provider, query) {
		const loginState = OAuth2Cookie.getLoginState(provider, query.state);
		if (loginState) {
			// cleanup
			OAuth2Cookie.removeLoginState(provider, query.state);
			return {
				providerId: provider.id,
				userId: query.subject || query.displayName || `${provider.id} user`,
				username: query.subject || query.displayName || `${provider.id} user`,
				display: query.displayName || query.subject || `${provider.id} user`,
				for: loginState.accountFor
			};
		}
	}

	static redirect(provider, searchParams) {
		// add account after login
		const account = OAuth2Cookie.accountFromLoginState(provider, Object.fromEntries(searchParams));
		if (account && !Account.getDefault(account.accountFor)) {
			// set default if none defined
			Account.setDefault(account);
		}
		// invalidate account after logout
		const logoutState = OAuth2Cookie.getLogoutState(provider, searchParams.get("state"));
		if (logoutState) {
			// cleanup
			OAuth2Cookie.removeLogoutState(provider, searchParams.get("state"));
			Account.remove(logoutState.account);
		}
		return false;
	}

	static gupportLogin(/*provider, account*/) {
		return {
			action: "login"
		};
	}

	static glientLogin(/*provider, account*/) {
		return {
			action: "login"
		};
	}

}

export default OAuth2Cookie;
