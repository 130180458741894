import { Route } from "react-router-dom";
import { stringify } from "qs";
import StatesStorage from "./states-storage";
// services
import Settings from "../services/settings";
import Account from "../services/accounts";
// pages
import VerifyPage from "./oauth2-verify-page";

class OAuth2 {

	static storageKey(provider, key) {
		return `providers/${provider.id}/${key}`;
	}

	static getLoginState(provider, state) {
		const storage = new StatesStorage(OAuth2.storageKey(provider, "loginStates"));
		return storage.getState(state);
	}

	static setLoginState(provider, state) {
		state.state ||= provider.state;
		const storage = new StatesStorage(OAuth2.storageKey(provider, "loginStates"));
		return storage.setState(state);
	}

	static getLogoutState(provider, state) {
		const storage = new StatesStorage(OAuth2.storageKey(provider, "logoutStates"));
		return storage.getState(state);
	}

	static setLogoutState(provider, state) {
		state.state ||= provider.state;
		const storage = new StatesStorage(OAuth2.storageKey(provider, "logoutStates"));
		return storage.setState(state);
	}

	static routes(provider) {
		return (
			<Route path="verify" element={<VerifyPage provider={provider} redirectTo={Settings.publicUrl} />} />
		);
	}

	static login(provider, accountFor) {
		const loginState = {
			accountFor: accountFor
		};
		OAuth2.setLoginState(provider, loginState);
		const params = {
			response_type: provider.responseType || "code",
			client_id: provider.clientId,
			redirect_uri: provider.redirectUrl || Settings.publicUrl,
			scope: provider.scope,
			state: loginState.state,
			resource: provider.resource
		};
		window.location = `${provider.endpoints.authorize}?${stringify(params)}`;
	}

	static logout(provider, account) {
		const logoutState = {
			account: account
		};
		OAuth2.setLogoutState(provider, logoutState);
		const params = {
			revoke: account.idToken,
			state: logoutState.state
		};
		window.location = `${provider.endpoints.revoke}?${stringify(params)}`;
	}

	static redirect(provider, searchParams) {
		// redirect to account verify after login
		const loginState = OAuth2.getLoginState(provider, searchParams.get("state"));
		if (loginState) {
			searchParams.set("accountFor", loginState.accountFor);
			window.location = `${Settings.publicUrl}?${searchParams.toString()}#/providers/${provider.id}/verify`;
			return true;
		}
		// invalidate account after logout
		const logoutState = OAuth2.getLogoutState(provider, searchParams.get("state"));
		if (logoutState) {
			Account.remove(logoutState.account);
		}
		return false;
	}

	static getIdToken(provider, query, done) {
		const url = provider.endpoints.token;
		const requestOptions = {
			grant_type: "authorization_code",
			code: query.code,
			client_id: provider.clientId,
			client_secret: provider.clientSecret,
			redirect_uri: provider.redirectUrl || Settings.publicUrl
		};

		fetch(url, {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(requestOptions)
		}).then((response) => {
			if (response.status !== 200) {
				const msg = "Unexpected HTTP status code";
				return done(new Error(msg), response);
			}
			return done(null, response);
		}).then((data) => {
			if (data && data.error) {
				return done(new Error(data.error_description || data.error), null);
			}
			return done(null, data.id_token, data.refresh_token);
		}).catch((error) => {
			done(error, null);
		});
	}

	static verifyIdToken(provider, idToken, done) {
		const url = provider.endpoints.tokenInfo;
		fetch(url, {
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"Authorization": idToken
			}
		}).then((response) => {
			if (response.status !== 200) {
				const msg = "Unexpected HTTP status code";
				return done(new Error(msg), response);
			}
			return done(null, response);
		}).then((data) => {
			if (data && data.error) {
				return done(new Error(data.error_description || data.error), null);
			}
			return done(null, data);
		}).catch((error) => {
			done(error, null);
		});
	}

	//
	// https://developers.google.com/identity/protocols/OAuth2ForDevices#refreshtoken
	//
	static refreshIdToken(provider, refreshToken, done) {
		const url = provider.endpoints.token;
		const requestOptions = {
			grant_type: "refresh_token",
			refresh_token: refreshToken,
			client_id: provider.clientId,
			client_secret: provider.clientSecret
		};
		fetch(url, {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(requestOptions)
		}).then((response) => {
			if (response.status !== 200) {
				const msg = "Unexpected HTTP status code";
				return done(new Error(msg), response);
			}
			return done(null, response);
		}).then((data) => {
			if (data && data.error) {
				return done(new Error(data.error_description || data.error), null);
			}
			return done(null, data.id_token);
		}).catch((error) => {
			done(error, null);
		});
	}

	static verify(provider, query, done) {
		OAuth2.getIdToken(provider, query, (error, idToken) => {
			if (error) {
				return done(error);
			}
			OAuth2.verifyIdToken(provider, idToken, (error, info) => {
				const account = {
					providerId: provider.id,
					userId: info.email,
					username: info.email,
					display: info.email,
					// exp: +info.exp,
					idToken: idToken,
					for: query.accountFor
				};
				done(error, account);
			});
		});
	}

	static gupportLogin(provider, account) {
		const login = {
			action: "login"
		};
		login[provider.gupportLoginIdTokenName] = account.idToken;
		return login;
	}

	static glientLogin(provider, account) {
		const login = {
			action: "login"
		};
		login[provider.glientLoginIdTokenName] = account.idToken;
		return login;
	}

}

export default OAuth2;
