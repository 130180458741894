import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// services
import resources from "@local/resources";

export const FALLBACK_LNG = "en";
export const DEFAULT_NS = "default";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		debug: process.env.BUILD_MODE !== "production",
		fallbackLng: FALLBACK_LNG,
		ns: DEFAULT_NS,
		defaultNS: DEFAULT_NS,
		// fallbackNS: DEFAULT_NS,
		interpolation: {
			escapeValue: false,
			prefix: "{",
			suffix: "}",
		},
		detection: {
			// order and from where user language should be detected
			order: ["navigator"], // default: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"]
		},
		resources: resources,
	})
	.then(() => {
		document.documentElement.setAttribute("lang", i18n.resolvedLanguage ?? "");
		window.addEventListener("languagechange", async () => {
			try {
				await i18n.changeLanguage();
				document.documentElement.setAttribute("lang", i18n.resolvedLanguage ?? "");
			} catch (error) {
				console.warn(error);
			}
		});
	})
	.catch((error) => {
		console.warn("i18n error", error);
	});

export default i18n;
