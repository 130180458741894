import Oauth2 from "../providers/oauth2";
import UserPassword from "../providers/user-password";
import Oauth2Cookie from "../providers/oauth2-cookie";
// types
import type { ConfigProvider } from "../types/config";
import type { AccountForWsUrl, Account } from "../types/account";
import type { CmdLogin } from "../types/message";
import type { Callback } from "../types/misc";

type Provider = typeof Oauth2 | typeof Oauth2Cookie | typeof UserPassword;

const PROVIDER_TYPES = {
	"oauth2": Oauth2,
	"user-password": UserPassword,
	"oauth2-cookie": Oauth2Cookie
};

class Providers {

	public static provider(provider: ConfigProvider): Provider {
		return PROVIDER_TYPES[provider.type];
	}

	public static routes(provider: ConfigProvider): JSX.Element | null {
		return PROVIDER_TYPES[provider.type].routes(provider);
	}

	public static login(provider: ConfigProvider, forWsUrl: AccountForWsUrl): void {
		PROVIDER_TYPES[provider.type].login(provider, forWsUrl);
	}

	public static logout(provider: ConfigProvider, account: Account): void {
		PROVIDER_TYPES[provider.type].logout(provider, account);
	}

	public static refresh(provider: ConfigProvider, account: Account, done: Callback<Account | null>): void {
		const refresh = PROVIDER_TYPES[provider.type].refresh;
		if (refresh) {
			refresh(provider, account, done);
		} else {
			done(null, null);
		}
	}

	public static redirect(providers: Array<ConfigProvider>, searchParams: URLSearchParams): boolean {
		return providers.some((provider) => (PROVIDER_TYPES[provider.type].redirect(provider, searchParams)));
	}

	public static gupportLogin(providers: Array<ConfigProvider>, account: Account): CmdLogin | null {
		const provider = providers.find((provider) => (provider.id === account.providerId));
		return provider ? PROVIDER_TYPES[provider.type].gupportLogin(provider, account) : null;
	}

	public static glientLogin(providers: Array<ConfigProvider>, account: Account): CmdLogin | null {
		const provider = providers.find((provider) => (provider.id === account.providerId));
		return provider ? PROVIDER_TYPES[provider.type].glientLogin(provider, account) : null;
	}

}

export default Providers;
