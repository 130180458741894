import { EventEmitter } from "events";
// services
import { Storage, StorageKeys } from "./storage";
import { merge } from "./utils";

let config = {};
let settings = {};

/**
 * Responsible for fetching the configuration and storing in memory
 * and local storage.
 *
 * @event clusterChanged
 * @event changed
 * @class Settings
 * @extends EventEmitter
 */
class Settings extends EventEmitter {

	constructor(options = {}) {
		super(options);

		this.loaded = false;
		this.permanent = options.permanent || false;
	}

	save() {
		if (this.permanent) {
			Storage.set(StorageKeys.settingsCluster, settings.cluster);
			Storage.set(StorageKeys.settingsChannel, settings.channel);
		} else {
			Storage.set(StorageKeys.settingsClusterId, settings.cluster.id || "");
		}
	}

	async #fetchConfig() {
		return fetch("config/config.json").then((response) => {
			if (response.ok || response.redirected) {
				return response.json();
			}
			throw new Error(response.statusText);
		});
	}

	load(done) {
		this.#fetchConfig().then((result) => {
			config = result || {};

			let cluster;
			if (this.permanent) {
				cluster = JSON.parse(Storage.get(StorageKeys.settingsCluster) || config.clusters[0]);
			} else {
				const clusterId = Storage.get(StorageKeys.settingsClusterId);
				cluster = config.clusters.find((cluster) => (cluster.id === clusterId)) || config.clusters[0];
			}
			const query = new URLSearchParams(window.location.href);
			let channel = query.get("channel");
			if (this.permanent && !channel) {
				channel = Storage.get(StorageKeys.settingsChannel);
			}
			channel ||= config.channel;
			for (const key in cluster) {
				if (cluster.hasOwnProperty(key) && typeof cluster[key] === "string") {
					cluster[key] = cluster[key].replace("{CHANNEL}", channel);
				}
			}
			settings = merge(config, {
				cluster: cluster,
				channel: channel
			});
			// set cluster to first cluster by default
			if (!settings.cluster && settings.clusters.length > 0) {
				settings.cluster = settings.clusters[0];
				this.save();
			}
			// update selected cluster if settings cluster has been modified
			const defaultCluster = settings.clusters.filter((cluster) => (cluster.id === settings.cluster.id));
			if (defaultCluster) {
				settings.cluster = merge(settings.cluster, defaultCluster);
			}
			this.loaded = true;
			done(null);
			this.emit("loaded");
		}).catch((error) => {
			done(error);
		});
	}

	resetDefault() {
		settings = structuredClone(config);
	}

	get publicUrl() {
		return settings.publicUrl;
	}

	get cluster() {
		return settings.cluster;
	}

	set cluster(value) {
		settings.cluster = value;
		this.emit("clusterChanged", value);
		this.emit("changed");
	}

	get clusters() {
		return settings.clusters;
	}

	get channel() {
		return settings.channel;
	}

	set channel(value) {
		settings.channel = value;
		this.emit("changed");
	}

	get autoRefresh() {
		return config.autoRefresh || false;
	}

	get autoRefreshDuration() {
		return config.autoRefreshDuration;
	}

	off(eventName, listener) { // New alias could be removed with Node.js 10 (https://nodejs.org/api/events.html#events_emitter_off_eventname_listener)
		return this.removeListener(eventName, listener);
	}

}

export default (new Settings());
