/* eslint-disable no-promise-executor-return */ // TODO
import { EventEmitter } from "events";
// services
import Settings from "./settings";
import Providers from "./providers";
import { StorageKeys, Storage } from "./storage";
// types
import type { AccountId, AccountForWsUrl, Account as AccountT } from "../types/account";
import type { Callback } from "../types/misc";

const refreshAccountTimers = {};

/**
 * Used to manage accounts of the user(s).
 * @class Account
 * @extends EventEmitter
 */
class Accounts extends EventEmitter {

	#_all: Array<AccountT> = [];
	#_defaults: Array<AccountT> = [];

	#getId(account: AccountT): AccountId {
		return `${account.providerId}/${account.userId}`;
	}

	public async load(): Promise<void> {
		return new Promise((resolve/*, reject */) => {
			const all: Array<AccountT> = Storage.get(StorageKeys.accounts) ?? [];
			this.#_all = all.map((account) => ({
				...account,
				id: this.#getId(account)
			}));
			const defaults: Array<AccountT> = Storage.get(StorageKeys.accountsDefaults) ?? [];
			this.#_defaults = defaults.map((account) => ({
				...account,
				id: this.#getId(account)
			}));
			// we do not refresh account on startup, just try to connect.
			// If it fails we redirect to login anyway
			const enableRefreshStartup = false;
			if (enableRefreshStartup && this.#_all.length > 0) {
				let loadedCount = 0;
				this.#_all.forEach((account) => {
					// refresh account on startup
					this.refreshAccount(account, (error, account) => {
						if (!error && account) {
							this.add(account);
						}
						loadedCount++;
						if (loadedCount >= this.#_all.length) {
							resolve();
						}
					});
				});
			} else {
				resolve();
			}
		});
	}

	public getDefault(forWsUrl: AccountForWsUrl): AccountT | null {
		return this.#_defaults.find((account) => (account.for === forWsUrl)) ?? null;
	}

	/**
	 * Set the account as default and fire setDefault event
	 * @param {Object} account
	 * @param {Boolean} dontAdd
	 * @event setDefault
	 */
	public setDefault(account: AccountT, dontAdd: boolean = false): void {
		if (!dontAdd) {
			this.add(account);
		}
		const accounts = this.#_defaults;
		const index = accounts.findIndex((item) => (item && item.for === account.for));
		const defaultAccount = this.getDefault(account.for);
		const isNewAccount = !defaultAccount || defaultAccount.id !== account.id;
		if (index > -1) {
			accounts[index] = account;
		} else {
			accounts.push(account);
		}
		Storage.set(StorageKeys.accountsDefaults, accounts);
		this.emit("setDefault", account.for, account, isNewAccount);
	}

	#removeDefault(forWsUrl: AccountForWsUrl): void {
		const accounts = this.#_defaults;
		const index = accounts.findIndex((item) => (item && item.for === forWsUrl));
		if (index > -1) {
			accounts.splice(index, 1);
			Storage.set(StorageKeys.accountsDefaults, accounts);
			this.emit("removeDefault", forWsUrl);
		}
	}

	public add(account: AccountT): void {
		const accounts = this.#_all;
		account.id = this.#getId(account);
		const index = accounts.findIndex((item) => (item && item.id === account.id));
		if (index > -1) {
			accounts[index] = account;
		} else {
			accounts.push(account);
		}
		Storage.set(StorageKeys.accounts, accounts);
		// update default account if necessary
		const defaultAccount = this.getDefault(account.for);
		if (defaultAccount && defaultAccount.id === account.id) {
			this.setDefault(account, true);
		}
		// we do not auto refreshing accounts, we refresh them on demand if
		// websockets connection has failed caused by invalid login
		// this.#startAutoRefreshAccount(account);
		this.emit("add", account);
	}

	public remove(account: AccountT): void {
		this.#stopAutoRefreshAccount(account);
		const accounts = this.#_all;
		const index = accounts.findIndex((a) => a.id === account.id);
		if (index > -1) {
			accounts.splice(index, 1);
			Storage.set(StorageKeys.accounts, accounts);
			this.emit("remove", account);
		}
		if (this.getDefault(account.for)?.id === account.id) {
			this.#removeDefault(account.for);
		}
	}

	#stopAutoRefreshAccount(account) {
		if (refreshAccountTimers[account.id]) {
			globalThis.clearTimeout(refreshAccountTimers[account.id]);
			refreshAccountTimers[account.id] = null;
		}
	}

	// #startAutoRefreshAccount(account) {
	// 	this.#stopAutoRefreshAccount(account);
	// 	let interval = account.exp * 1000 - Date.now();
	// 	if (Number.isNaN(account.exp) || account.exp === null || Number.isNaN(interval)) {
	// 		return;
	// 	}
	// 	interval = interval < 2000 ? 2000 : interval;
	// 	refreshAccountTimers[account.id] = globalThis.setTimeout(() => {
	// 		this.refreshAccount(account, (error, account) => {
	// 			if (account) {
	// 				this.add(account);
	// 			}
	// 		});
	// 	}, Number(interval));
	// }

	public refreshAccount(account: AccountT, done: Callback<AccountT | null>): void {
		const providers = Settings.cluster ? Settings.cluster.providers || [] : [];
		const provider = providers.find((p) => (p.id === account.providerId));
		if (!provider) {
			done(null, null);
		}
		Providers.refresh(provider, account, (error, newAccount) => {
			if (!newAccount) {
				// clean up if refresh has failed
				this.remove(account);
			}
			done(error, newAccount);
		});
	}

}

export default (new Accounts());
