import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
	Collapse,
	List,
	ListItemButton,
	ListItemAvatar,
	ListItemText,
	Divider
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Svg from "../svg";
// services
import { Storage, StorageKeys } from "../../services/storage";
import { icons } from "@local/theme";
// types
import type { ReactNode } from "react";
import type { MenuItems, MenuItemSubMenu } from "../../types/menu";

type Props = {
	activeRoute: string;
	item: MenuItemSubMenu;
	renderNestedMenu: (menuItems: MenuItems, nested: boolean, activeRoute: string) => ReactNode;
};

const NavigationNestedMenu = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const active = props.item.items.some((item) => (item.id === props.activeRoute));
	const [open, setOpen] = useState(active || (Storage.get(StorageKeys.menu.replace("{ID}", props.item.id) as "menu/{ID}") ?? false));

	useEffect(() => {
		if (active) {
			setOpen(active);
		}
	}, [active]);

	useEffect(() => {
		Storage.set(StorageKeys.menu.replace("{ID}", props.item.id) as "menu/{ID}", open);
	}, [open]);

	const IconElement = props.item.icon;

	return (
		<>
			{!props.item.singleDivider && <Divider />}
			<ListItemButton
				id={`main-menu-${props.item.id}`}
				style={{ color: open ? theme.palette.text.primary : theme.palette.text.secondary }}
				onClick={() => (setOpen((prevOpen) => (!prevOpen)))}
			>
				{IconElement && <ListItemAvatar><IconElement /></ListItemAvatar>}
				<ListItemText>{t(props.item.label)}</ListItemText>
				<ListItemAvatar className="ListItemIcon-right" style={{ display: "flex" }}>
					{open ? <icons.ExpandLess /> : <Svg src="navigation/arrowDropDown.svg" />}
				</ListItemAvatar>
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit={true}>
				<List component="div" disablePadding={true}>
					{props.renderNestedMenu(props.item.items, true, props.activeRoute)}
				</List>
			</Collapse>
			<Divider />
		</>
	);
};

NavigationNestedMenu.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		icon: PropTypes.object,
		singleDivider: PropTypes.bool,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				isVisible: PropTypes.func
			})
		).isRequired
	}).isRequired,
	activeRoute: PropTypes.string.isRequired,
	renderNestedMenu: PropTypes.func.isRequired
};

export default NavigationNestedMenu;
