import PropTypes from "prop-types";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// contexts
import { ExpertModeProvider } from "./scripts/context/ExpertModeContext";
// hooks
import useThemeMode from "./scripts/hooks/useThemeMode";
// services
import { getMuiTheme } from "@local/theme";
// types
import type { ReactNode } from "react";

type Props = {
	injectAdditionalContextProviders: boolean;
	children: ReactNode;
};

const Base = (props: Props) => {
	const themeMode = useThemeMode();

	return (
		<ThemeProvider theme={getMuiTheme(themeMode)}>
			<CssBaseline enableColorScheme={true} />
			{props.injectAdditionalContextProviders ?
				<ExpertModeProvider>
					{props.children}
				</ExpertModeProvider>
				: props.children
			}
		</ThemeProvider>
	);
};

Base.defaultProps = {
	injectAdditionalContextProviders: false
};

Base.propTypes = {
	injectAdditionalContextProviders: PropTypes.bool,
	children: PropTypes.node.isRequired
};

export default Base;
