import { EventEmitter } from "events";
// services
import Constants from "../services/constants";
import Cookies from "../lib/cookies";
// @ts-expect-error: window.appInfo
import appInfo from "@local/appInfo";
// types
import type { ValueOf } from "type-fest";
import type { Channel, ConfigCluster } from "../types/config";
import type { Accounts } from "../types/account";
import type { GatewayId } from "../types/gateway";
import type { Appearance, AppInfo } from "../types/misc";

export const StorageKeys = {
	expertMode: "expertMode", // true,false
	darkMode: "darkMode", // true,false
	settingsCluster: "settings/cluster",
	settingsClusterId: "settings/clusterId",
	settingsChannel: "settings/channel", // "hornbach","ozom","roc"
	accounts: "accounts",
	accountsDefaults: "accounts/defaults",
	menu: "menu/{ID}",
	gatewaysTab: "gateways/tab",
	usersTab: "users/tab",
	gatewayId: "gateways/gatewayId",
	userId: "users/userId",
	supportUserId: "support-users/userId",
	teditorTable: "teditor/table",
	rocIdFilterQuery: "rocIdFilterQuery",
	metadataEditorSearchAll: "metadataEditorSearchAll",
	metadataEditorSearchByRegex: "metadataEditorSearchByRegex",
	appearance: "appearance"
} as const;

const defaultValues = {
	[StorageKeys.expertMode]: false,
	[StorageKeys.darkMode]: false,
	[StorageKeys.settingsCluster]: undefined,
	[StorageKeys.settingsClusterId]: undefined,
	[StorageKeys.settingsChannel]: null,
	[StorageKeys.accounts]: [] as Accounts,
	[StorageKeys.accountsDefaults]: [] as Accounts,
	[StorageKeys.menu]: null,
	[StorageKeys.gatewaysTab]: null,
	[StorageKeys.usersTab]: null,
	[StorageKeys.gatewayId]: null,
	[StorageKeys.userId]: null,
	[StorageKeys.supportUserId]: null,
	[StorageKeys.teditorTable]: null,
	[StorageKeys.rocIdFilterQuery]: "",
	[StorageKeys.metadataEditorSearchAll]: true,
	[StorageKeys.metadataEditorSearchByRegex]: false,
	[StorageKeys.appearance]: Constants.Appearance.System
};

type StorageKeyPrefix = "" | `${string}/${string}/`;
type StorageKey = ValueOf<typeof StorageKeys>;
type StorageKeyWithPrefix = `${StorageKeyPrefix}${StorageKey}`;
type StorageValue = ValueOf<StorageValues> | null;
type StorageValueWithDefault<K extends StorageKey> = StorageValues[K] | typeof defaultValues[K];
interface StorageValues {
	[StorageKeys.expertMode]: boolean;
	[StorageKeys.darkMode]: boolean;
	[StorageKeys.settingsCluster]: ConfigCluster;
	[StorageKeys.settingsClusterId]: string;
	[StorageKeys.settingsChannel]: Channel;
	[StorageKeys.accounts]: Accounts;
	[StorageKeys.accountsDefaults]: Accounts;
	[StorageKeys.menu]: boolean;
	[StorageKeys.gatewaysTab]: string;
	[StorageKeys.usersTab]: string;
	[StorageKeys.gatewayId]: GatewayId;
	[StorageKeys.userId]: string;
	[StorageKeys.supportUserId]: string;
	[StorageKeys.teditorTable]: string;
	[StorageKeys.rocIdFilterQuery]: string;
	[StorageKeys.metadataEditorSearchAll]: boolean;
	[StorageKeys.metadataEditorSearchByRegex]: boolean;
	[StorageKeys.appearance]: Appearance;
}

interface StorageT {
	type: "localStorage" | "sessionStorage" | "cookie" | "memory";
	isAvailable: () => boolean;
	needsPrefix: () => boolean;
	updateItems: () => void;
	getItem: (key: StorageKeyWithPrefix) => StorageValue;
	setItem: (key: StorageKeyWithPrefix, value: StorageValue) => void;
	removeItem: (key: StorageKeyWithPrefix) => void;
}

/**
 * A service class responsible to store the data in browser.
 * The storage used is localstorage then sessionStorage then cookies then memory.
 */

const getLocalOrSessionStorage = (storageType: "localStorage" | "sessionStorage"): StorageT => ({
	type: storageType,
	isAvailable: () => {
		try {
			const storageTestString = "__storage_test__";
			window[storageType].setItem(storageTestString, storageTestString);
			window[storageType].removeItem(storageTestString);
			return true;
		} catch (e) {
			return false;
		}
	},
	needsPrefix: () => true,
	updateItems: () => {
		for (let i = 0; i < window[storageType].length; i++) {
			const key = window[storageType].key(i);
			const value = window[storageType].getItem(key);
			try {
				JSON.parse(value);
			} catch (e) {
				window[storageType].setItem(key, JSON.stringify(value));
			}
		}
	},
	getItem: (key) => (JSON.parse(window[storageType].getItem(key))),
	setItem: (key, value) => (window[storageType].setItem(key, JSON.stringify(value))),
	removeItem: (key) => (window[storageType].removeItem(key))
} as StorageT);

const memoryStorage = new Map<string, any>();

const localStorage = getLocalOrSessionStorage("localStorage");
const sessionStorage = getLocalOrSessionStorage("sessionStorage");
const cookie: StorageT = {
	type: "cookie",
	isAvailable: () => (navigator.cookieEnabled),
	needsPrefix: () => false,
	updateItems: () => {
		Cookies.keys().forEach((key) => {
			const value = Cookies.getItem(key);
			try {
				JSON.parse(value);
			} catch (e) {
				Cookies.setItem(key, JSON.stringify(value), Infinity);
			}
		});
	},
	getItem: (key) => (JSON.parse(Cookies.getItem(key))),
	setItem: (key, value) => (Cookies.setItem(key, JSON.stringify(value), Infinity)),
	removeItem: (key) => (Cookies.removeItem(key))
};
const memory: StorageT = {
	type: "memory",
	isAvailable: () => true,
	needsPrefix: () => false,
	updateItems: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
	getItem: (key) => (memoryStorage.get(key)),
	setItem: (key, value) => (memoryStorage.set(key, value)),
	removeItem: (key) => (memoryStorage.delete(key))
};

class StorageApi extends EventEmitter {

	#storage: StorageT;
	#storageKeyPrefix: StorageKeyPrefix = "";

	constructor() {
		super();

		this.setMaxListeners(256); // a listener per device in the devices view

		const storages = [localStorage, sessionStorage, cookie];
		this.#storage = storages.find((storage) => (storage.isAvailable())) ?? memory;
		this.#storage.updateItems();

		if (this.#storage.needsPrefix()) {
			const lastSlashIndex = window.location.pathname.lastIndexOf("/");
			const pathname = (lastSlashIndex === -1) ? window.location.pathname : window.location.pathname.substring(0, lastSlashIndex);
			this.#storageKeyPrefix = `${pathname}/${(appInfo as AppInfo).name}/` as StorageKeyPrefix;
		}
	}

	public get<K extends StorageKey>(storageKey: K): StorageValueWithDefault<K> {
		return this.#storage.getItem(`${this.#storageKeyPrefix}${storageKey}` as StorageKeyWithPrefix) as StorageValues[K] | null ?? defaultValues[storageKey];
	}

	public set<K extends StorageKey>(storageKey: K, storageValue: StorageValueWithDefault<K>): void {
		this.#storage.setItem(`${this.#storageKeyPrefix}${storageKey}` as StorageKeyWithPrefix, storageValue);
		this.emit(`${storageKey}Changed`, storageValue);
	}

	public remove(storageKey: StorageKey): void {
		this.#storage.removeItem(`${this.#storageKeyPrefix}${storageKey}` as StorageKeyWithPrefix); // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
		this.emit(`${storageKey}Removed`);
	}

}
export const Storage = new StorageApi();
