import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { CircularProgress, Card, CardActions, CardHeader, CardContent, Button } from "@mui/material";
import { parse } from "qs";
import Svg from "../cmp/svg";
// services
import Accounts from "../services/accounts";
import OAuth2 from "./oauth2";
class Oauth2VerifyPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			loading: false,
			account: null
		};

		this.handleBack = this.handleBack.bind(this);
		this.handleAddAccount = this.handleAddAccount.bind(this);
	}

	componentDidMount() {
		this.setState({
			loading: true
		});
		OAuth2.verify(this.props.provider, parse(location.search.substring(1)), (error, account) => {
			this.setState({
				error: error,
				account: error ? null : account,
				loading: false
			});
		});
	}

	componentWillUnmount() {
		// TODO: abort getaccount !!
	}

	handleBack() {
		window.location = this.props.redirectTo;
	}

	handleAddAccount() {
		Accounts.add(this.state.account);
		if (!Accounts.getDefault(this.state.account.for)) {
			// set default if none defined
			Accounts.setDefault(this.state.account);
		}
		window.location = this.props.redirectTo;
	}

	render() {
		const { t } = this.props;

		let avatar;
		if (this.state.loading) {
			avatar = <CircularProgress />;
		} else if (this.state.error) {
			avatar = <Svg src="messages/error.svg" />;
		} else if (this.state.account) {
			avatar = <Svg src="messages/success.svg" />;
		}

		return (
			<Card style={{ margin: "20px" }}>
				<CardHeader
					title="Verifying account"
					subtitle={`Verifying account by ${this.props.provider.label}`}
					avatar={avatar}
				/>
				<CardContent>
					{
						this.state.error
							? <span>{t("generic.errorWithMsg", { msg: this.state.error.message})}</span>
							: this.state.loading
								? <span>{t("oauth2Verify.wait")}</span>
								: this.state.account ?
									<table>
										<caption>{t("oauth2Verify.accountInfo")}</caption>
										<tbody>
											<tr><th>{t("oauth2Verify.userId")}</th><td>{this.state.account.userId}</td></tr>
											<tr><th>{t("oauth2Verify.providerId")}</th><td>{this.state.account.providerId}</td></tr>
										</tbody>
									</table>
									: <span>{t("oauth2Verify.wentWrong")}</span>
					}
				</CardContent>
				<CardActions>
					{
						this.state.error ?
							<Button variant="contained" color="neutral" onClick={this.handleBack}>
								{t("generic.goBackToApp")}
							</Button>
							: this.state.account ?
								<Button variant="contained" color="neutral" onClick={this.handleAddAccount}>
									{t("generic.addAccount")}
								</Button>
								: ""
					}
				</CardActions>
			</Card>
		);
	}

}

Oauth2VerifyPage.defaultProps = {
	redirectTo: "",
	provider: null
};

Oauth2VerifyPage.propTypes = {
	redirectTo: PropTypes.string,
	provider: PropTypes.shape({
		label: PropTypes.string
	}),
	t: PropTypes.func.isRequired
};

export default withTranslation()(Oauth2VerifyPage);
