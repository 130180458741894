import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
// services
import Gupport from "../../services/gupport";
import Providers from "../../services/providers";
import Settings from "../../services/settings";

type Props = {
	doNotRedirect: boolean;
};

const ConnectionFailedDialog = (props: Props) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [gupportPayload, setGupportPayload] = useState(null);

	const handleGupportLoginFailed = (msg) => {
		let redirected = false;
		if (!props.doNotRedirect) {
			let providerId = Gupport.account ? Gupport.account.providerId : null;
			if (!providerId && Settings.cluster.providers.length > 0) {
				providerId = Settings.cluster.providers[0].id;
			}
			const provider = Settings.cluster.providers.find((provider) => (provider.id === providerId));
			if (provider) {
				redirected = true;
				Providers.login(provider, Settings.cluster.gupportWsUrl);
			}
		}
		if (!redirected) {
			setOpen(true);
			setGupportPayload(msg.payload);
		}
	};

	useEffect(() => {
		Gupport.on("loginFailed", handleGupportLoginFailed);

		return () => {
			Gupport.off("loginFailed", handleGupportLoginFailed);
		};
	}, []);

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => (setOpen(false))}
		>
			<DialogTitle>{t("dialog.title.connectionFailed")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Trans
						i18nKey="dialog.content.connectionFailed" // optional -> fallbacks to defaults if not provided
						values={{ accountId: Gupport.account ? Gupport.account.id : "none", response: JSON.stringify(gupportPayload, null, 2) }}
						components={{ p: <p />, pre: <pre /> }}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => (setOpen(false))}>{t("dialog.ok")}</Button>
			</DialogActions>
		</Dialog>
	);
};

ConnectionFailedDialog.defaultProps = {
	doNotRedirect: false
};

ConnectionFailedDialog.propTypes = {
	doNotRedirect: PropTypes.bool
};

export default ConnectionFailedDialog;
