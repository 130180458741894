import { Children } from "react";
import PropTypes from "prop-types";
// types
import type { ReactNode } from "react";

type Props = {
	width?: string;
	page?: string; // Used in PagePanel
	children?: ReactNode;
};

const SubPage = (props: Props) => (
	<div style={{width: (props.width), marginTop: "65px" }}>
		{Children.map(props.children, (child) => (child))}
	</div>
);

SubPage.defaultProps = {
	width: "100%",
	page: undefined,
	children: null
};

SubPage.propTypes = {
	width: PropTypes.string,
	page: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
	children: PropTypes.node
};

export default SubPage;
