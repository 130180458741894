// cmp
import PagePanel from "./page-panel";
// services
import Responsive from "../services/responsive";

class ResponsivePagePanel extends PagePanel {

	constructor(props) {
		super(props);

		const navigationDrawerDockedScreen = props.navigationDrawerDockedScreen || "lg";
		this.state.navigationDrawerDockedScreen = navigationDrawerDockedScreen;
		this.state.navigationDrawerDocked = Responsive.getScreenSizes().includes(navigationDrawerDockedScreen);
		this.state.initiallyDocked = this.state.navigationDrawerDocked;

		this.handleScreenChanged = this.handleScreenChanged.bind(this);
	}

	componentDidMount() {
		Responsive.on("screenChanged", this.handleScreenChanged);
	}

	componentWillUnmount() {
		Responsive.off("screenChanged", this.handleScreenChanged);
	}

	componentDidUpdate(prevProps) {
		if (this.props.hideAppBar !== prevProps.hideAppBar || this.props.onBack !== prevProps.onBack) {
			this.setState({
				hideAppBar: this.props.hideAppBar && !this.props.onBack
			});
		}
		if (this.props.navigationDrawerDockedScreen !== prevProps.navigationDrawerDockedScreen) {
			this.setState({
				navigationDrawerDockedScreen: this.props.navigationDrawerDockedScreen || "lg"
			});
		}
	}

	handleScreenChanged() {
		const docked = Responsive.getScreenSizes().includes(this.state.navigationDrawerDockedScreen);
		this.setState({
			navigationDrawerDocked: docked,
			initiallyDocked: docked
		});
	}

}

export default ResponsivePagePanel;
