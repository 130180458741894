import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Card, CardActions, CardHeader, CardContent, TextField } from "@mui/material";
import { parse } from "qs";
import Svg from "../cmp/svg";
// services
import Accounts from "../services/accounts";
import UserPassword from "./user-password";

class UserPasswordVerifyPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: ""
		};

		this.handleBack = this.handleBack.bind(this);
		this.handleAddAccount = this.handleAddAccount.bind(this);
		this.handlerUsernameChanged = this.handlerUsernameChanged.bind(this);
		this.handlerPasswordChanged = this.handlerPasswordChanged.bind(this);
	}

	handleBack() {
		window.location = this.props.redirectTo;
	}

	handleAddAccount() {
		const account = UserPassword.verify(this.props.provider, parse(location.search.substring(1)), this.state.username, this.state.password);
		Accounts.add(account);
		if (!Accounts.getDefault(account.for)) {
			// set default if none defined
			Accounts.setDefault(account);
		}
		window.location = this.props.redirectTo;
	}

	handlerUsernameChanged(event) {
		this.setState({
			username: event.target.value
		});
	}

	handlerPasswordChanged(event) {
		this.setState({
			password: event.target.value
		});
	}

	render() {
		const { t } = this.props;
		return (
			<Card style={{margin: "20px"}}>
				<CardHeader
					title="Add User/Password account"
					subtitle={`Verifying account by ${this.props.provider.label}`}
					avatar={<Svg src="messages/success.svg" />}
				/>
				<CardContent>
					<TextField
						placeholder="Username"
						label="Username"
						value={this.state.username}
						onChange={this.handlerUsernameChanged}
					/><br />
					<TextField
						placeholder="Password"
						label="Password"
						type="password"
						value={this.state.password}
						onChange={this.handlerPasswordChanged}
					/>
				</CardContent>
				<CardActions>
					<Button color="neutral" onClick={this.handleBack}>{t("generic.goBackToApp")}</Button>
					<Button color="neutral" onClick={this.handleAddAccount}>{t("generic.addAccount")}</Button>
				</CardActions>
			</Card>
		);
	}

}

UserPasswordVerifyPage.defaultProps = {
	redirectTo: "",
	provider: null
};

UserPasswordVerifyPage.propTypes = {
	redirectTo: PropTypes.string,
	provider: PropTypes.shape({
		label: PropTypes.string
	}),
	t: PropTypes.func.isRequired
};

export default withTranslation()(UserPasswordVerifyPage);
