import RocWs from "./roc-ws";
// services
import Settings from "./settings";
import Accounts from "./accounts";
import Providers from "./providers";

class Gupport extends RocWs {

	constructor(options) {
		super(options);

		this.onSetDefaultAccount = this.onSetDefaultAccount.bind(this);
		this.onClusterChanged = this.onClusterChanged.bind(this);
		this.onSettingsLoaded = this.onSettingsLoaded.bind(this);
		this.onApiSchemasMsg = this.onApiSchemasMsg.bind(this);
		this.requestApiSchemas = this.requestApiSchemas.bind(this);
		this.onDisconnected = this.onDisconnected.bind(this);

		this.ready = false;
		this.apiSchemas = [];
		this.apiSchemasGlient = [];
		this.account = null;
		this.on("error", (error) => (console.warn(error))); // eslint-disable-line no-console
		this.on("disconnected", this.onDisconnected);

		Accounts.on("setDefault", this.onSetDefaultAccount);
		Settings.on("clusterChanged", this.onClusterChanged);
		Settings.on("loaded", this.onSettingsLoaded);

		if (Settings.loaded) {
			this.onSettingsLoaded();
		}
	}

	onDisconnected(data) {
		if (data.reason === "loginFailed" && this.account) {
			Accounts.refreshAccount(this.account, (error, account) => {
				if (account) {
					// this triggers the Accounts 'setDefault' event, so the ws will
					// reconnect if not already connected (see onAccountChanged)!
					Accounts.add(account);
				} else {
					this.emit("loginFailed", data);
				}
			});
		}
	}

	requestApiSchemas() {
		this.removeActionMethods(this.apiSchemas.filter((schema) => (schema.data.dir === "TX")));
		this.apiSchemas = [];
		this.apiSchemasGlient = [];
		// load api-schemas for gupport
		this.send({
			action: "getTable",
			tableName: "api_schemas"
		}, this.onApiSchemasMsg);
	}

	onAccountChanged() {
		const newAccount = Accounts.getDefault(Settings.cluster.gupportWsUrl);
		if (this.connected && newAccount && this.account && newAccount.id === this.account.id) {
			// do not reconnect if account has not changed
			return;
		}
		this.off("connected", this.requestApiSchemas);
		this.account = newAccount;
		let login = null;
		if (this.account && Settings.cluster.providers) {
			login = Providers.gupportLogin(Settings.cluster.providers, this.account);
		}
		this.setOptions({
			url: Settings.cluster.gupportWsUrl,
			login: login,
			heartbeat: true,
			channel: Settings.channel
		});
		this.ready = false;
		this.once("connected", this.requestApiSchemas);
		this.reconnect();
	}

	onApiSchemasMsg(error, msg) {
		if (!error && msg.payload.status !== "ok") {
			error = new Error(JSON.stringify(msg.payload, null, 2));
		}
		if (error) {
			this.emit("error", error);
		} else {
			this.apiSchemas = [];
			this.apiSchemasGlient = [];
			this.addApiSchemas(msg.payload.data);
			this.addActionMethods(msg.payload.data.filter((schema) => (schema.data.dir === "TX" && schema.data.api === "gupport")));
			this.ready = true;
			this.emit("ready");
		}
	}

	onSetDefaultAccount(accountFor) {
		if (accountFor === Settings.cluster.gupportWsUrl) {
			this.onAccountChanged();
		}
	}

	onClusterChanged() {
		this.onAccountChanged();
	}

	onSettingsLoaded() {
		this.onAccountChanged();
	}

	sendAction(actionName, payload, callback) {
		payload ||= {};
		payload.action = actionName;

		return this.send(payload, callback);
	}

	addApiSchemas(schemas) {
		const gupportSchemas = schemas.filter((schema) => (schema.data.api === "gupport"));
		const glientSchemas = schemas.filter((schema) => (schema.data.api === "glient"));
		this.apiSchemas = this.apiSchemas.concat(gupportSchemas);
		this.apiSchemasGlient = this.apiSchemasGlient.concat(glientSchemas);
	}

	addActionMethods(schemas) {
		schemas.forEach((schema) => {
			const action = schema.data.payload.action;
			this[action] = this.sendAction.bind(this, action);
		});
	}

	removeActionMethods(schemas) {
		schemas.forEach((schema) => {
			const action = schema.data.payload.action;
			delete this[action];
		});
	}

	hasLevel(level) {
		if (this.connected && this.user && this.user.level) {
			return this.user.level.includes(level);
		}
		return false;
	}

	hasAnyLevel(levels) {
		return levels.some((level) => (this.hasLevel(level)));
	}

	getLevels() {
		return this.apiSchemas
			.map((apiSchema) => (apiSchema.data.rbac))
			.filter((rbac, index, levels) => (rbac && levels.indexOf(rbac) === index));
	}

	getUserLevels() {
		if (this.connected && this.user && this.user.level) {
			return this.user.level;
		}
		return null;
	}

	logout() {
		const providers = Settings.cluster.providers || [];
		const provider = providers.find((provider) => (provider.id === this.account.providerId));
		if (provider) {
			Providers.logout(provider, this.account);
		}
	}

}

export default (new Gupport({heartbeat: true}));
