import { EventEmitter } from "events";
// types
import type {
	HandlerId,
	MessageErrorRX,
	MessageTxKnown, PayloadTxKnown,
	MessageRxKnown, PayloadRxKnown,
	CmdKeepAlive, CmdLogin, MsgResponseLogin
} from "../types/message";
import type { Options, MsgDataWithPayload, MsgData, MessageResponseHandler, Action, RxCallback } from "../types/roc-ws";
import type { Callback } from "../types/misc";

const HEART_BEAT_SECONDS = 55;

const MSG_DATA_UNKNOWN = {
	reason: "unknown",
} as const;

export const checkIsAuthenticated = (url: string, callback: Callback<boolean>): void => {
	fetch(url.replace(/^ws/, "http")).then((response) => {
		if (response.status > 399 && response.status !== 401) {
			throw new Error(response.statusText);
		}
		return response.status !== 401;
	}).then((isAuthenticated) => {
		callback(null, isAuthenticated);
	}).catch((error) => {
		callback(error, false);
	});
};

/**
 * @event connecting
 * @event connected
 * @event disconnected
 * @event error
 * @event expired
 * @event loggedIn
 * @event message-tx
 * @event message-rx
 */
class RocWs extends EventEmitter {

	protected connected = false;
	protected connecting = false;
	protected disconnecting = false;

	#responseListeners = new Map<HandlerId, MessageResponseHandler>();

	#connectRetryId: number | null = null;
	#heartbeatId: number | null = null;

	#url = "";
	#loginPayload: CmdLogin | null = null;
	#connectRetryTimeout = 5000;
	#autoReconnect = true;
	#heartbeat: boolean | null = null;
	#channel: string | null = null;

	#ws: WebSocket | null = null;

	constructor(options: Options) {
		super();

		this.setMaxListeners(32);

		this.setMaxListeners(32);

		this.setOptions(options);

		this.sendHeartbeat = this.sendHeartbeat.bind(this);
		// setup ws event handlers
		this.handleWsOpen = this.handleWsOpen.bind(this);
		this.handleWsClose = this.handleWsClose.bind(this);
		this.handleWsMessage = this.handleWsMessage.bind(this);
		this.handleWsError = this.handleWsError.bind(this);
	}

	protected setOptions(options: Options): void {
		this.#url = options.url;
		this.autoLogin = options.login && (options.autoLogin !== false);
		this.#loginPayload = options.login ?? null;
		this.#connectRetryTimeout = options.connectRetryTimeout ?? 5000;
		this.#autoReconnect = options.autoReconnect !== false;
		if (options.heartbeat !== undefined) {
			this.#heartbeat = Boolean(options.heartbeat);
		}
		this.#channel = options.channel ?? null;
		// this.#loginPayload.channel = this.#channel;
	}

	protected connect(): void {
		if (this.connected || this.connecting || this.disconnecting) {
			return;
		}
		this.connecting = true;
		this.emit("connecting");
		this.#ws = null;
		try {
			this.#ws = new WebSocket(this.#url);
		} catch (error) {
			this.#ws = null;
			this.handleWsError(error);
		}
		if (this.#ws) {
			this.#ws.addEventListener("open", this.handleWsOpen);
			this.#ws.addEventListener("close", this.handleWsClose);
			this.#ws.addEventListener("message", this.handleWsMessage);
			this.#ws.addEventListener("error", this.handleWsError);
		}
	}

	protected disconnect(msgData: MsgData = MSG_DATA_UNKNOWN): void {
		if (this.disconnecting) {
			return;
		}
		this.disconnecting = true;
		if (this.#connectRetryId) {
			globalThis.clearTimeout(this.#connectRetryId);
		}
		if (this.#heartbeatId) {
			globalThis.clearInterval(this.#heartbeatId);
		}
		const closeHandler = (): void => {
			if (this.#ws) {
				this.#ws.removeEventListener("close", closeHandler);
				this.#ws = null;
			}
			this.connected = false;
			this.connecting = false;
			this.disconnecting = false;
			this.emit("disconnected", msgData);
		};
		if (this.#ws) {
			this.#ws.removeEventListener("open", this.handleWsOpen);
			this.#ws.removeEventListener("close", this.handleWsClose);
			this.#ws.removeEventListener("message", this.handleWsMessage);
			this.#ws.removeEventListener("error", this.handleWsError);
			if (this.#ws.readyState === WebSocket.CLOSED) {
				closeHandler();
			} else {
				this.#ws.addEventListener("close", closeHandler);
				this.#ws.close();
			}
		} else {
			closeHandler();
		}
	}

	protected reconnect(): void {
		this.once("disconnected", () => (this.connect()));
		this.disconnect();
	}

	private sendHeartbeat(): void {
		const cmd: CmdKeepAlive = {
			action: "keepAlive",
			data: new Date().toISOString()
		};
		this.send(cmd);
	}

	/**
	 * // @param {Event} event
	 */
	private handleWsOpen(/*event: Event*/): void {
		if (this.autoLogin) {
			this.#txLogin();
		} else {
			this.connecting = false;
			this.connected = true;
			this.emit("connected", {
				url: this.#url
			});
		}
		if (this.#heartbeatId) {
			globalThis.clearInterval(this.#heartbeatId);
		}
		if (this.#heartbeat) {
			this.#heartbeatId = setInterval(this.sendHeartbeat, HEART_BEAT_SECONDS * 1000);
		}
	}

	/**
	 * @param {CloseEvent} event
	 */
	private handleWsClose(event: CloseEvent): void {
		// If we close, we need to reopen
		this.connected = false;
		this.connecting = false;
		if (this.#connectRetryId) {
			globalThis.clearTimeout(this.#connectRetryId);
		}
		if (this.#heartbeatId) {
			globalThis.clearInterval(this.#heartbeatId);
		}
		const handler = (reconnect: boolean, msgData: MsgData = MSG_DATA_UNKNOWN): void => {
			if (reconnect) {
				this.#connectRetryId = setTimeout(
					() => (this.connect()),
					this.#connectRetryTimeout
				);
			}
			this.emit("disconnected", msgData);
		};
		if (event.code === 1006) {
			// ws server response was 401 Unauthorized
			// this happens if authentication via HTTP Header (ex. Cookie)
			// has failed
			// !!! Attention !!!
			// code === 1006 is not enough to determine if close was caused by
			// 401 Unauthorized. For example, code 1006 is also returned if the ws url
			// points to a non existing endpoint (404 Not Found).
			// Maybe the server should return a reason with the ws close frame body,
			// see
			// https://tools.ietf.org/html/rfc6455#section-7.1.6
			// https://tools.ietf.org/html/rfc6455#section-5.5.1
			// Then we could check e.reason for the reason.
			// For now we make a http request to ws endpoint which returns 401
			// if unauthorized to not break the API for other apps.
			checkIsAuthenticated(this.#url, (error, isAuthenticated) => {
				if (!error && !isAuthenticated) {
					const msgData: MsgDataWithPayload = {
						reason: "loginFailed",
						payload: {message: "header auth failed"},
						url: this.#url
					};
					handler(false, msgData);
				} else {
					handler(this.#autoReconnect);
				}
			});
		} else {
			handler(this.#autoReconnect);
		}
	}

	/**
	 * @param {MessageEvent} event
	 */
	private handleWsMessage(event: MessageEvent): void {
		try {
			const payload: PayloadRxKnown = JSON.parse(event.data);

			const msg: MessageRxKnown = {
				dir: "RX",
				error: (payload.status === "error") ? new Error(payload.data ?? "Unknown error") : null,
				payload: payload,
				raw: event.data
			};
			this.emit("message", msg);
		} catch (error) {
			const msg: MessageErrorRX = {
				dir: "RX",
				error: error,
				payload: null,
				raw: event.data
			};
			this.emit("message", msg);
		}
	}

	/**
	 * @param {Event} event
	 */
	private handleWsError(event: Event): void {
		this.emit("error", event);
	}

	#setResponseListener<A extends Action>(handlerId: HandlerId, callback: RxCallback<A>, timeout: number, action: A): void {
		if (!handlerId) {
			callback(new Error("handlerId is missing"));
		}
		let timeoutId: number | null = null;
		if (timeout > 0) {
			timeoutId = setTimeout(() => {
				this.#clearResponseListener(handlerId);
				callback(new Error("Timeout"));
			}, timeout);
		}
		const messageResponseHandler: MessageResponseHandler = (msg) => {
			if (!msg.payload || (msg.payload.responseId && msg.payload.responseId !== handlerId) || (!msg.payload.responseId && msg.payload.info !== action)) {
				return;
			}
			if (timeoutId) {
				globalThis.clearTimeout(timeoutId);
			}
			this.#clearResponseListener(handlerId);
			callback(msg.error, msg);
		};
		// add listener
		this.#responseListeners.set(handlerId, messageResponseHandler);
		this.on("message", messageResponseHandler);
	}

	#clearResponseListener(handlerId: HandlerId): void {
		if (!this.#responseListeners.get(handlerId)) {
			return;
		}
		this.off("message", this.#responseListeners.get(handlerId));
		this.#responseListeners.delete(handlerId);
	}

	public send<P extends PayloadTxKnown>(payload: P, callback: RxCallback<P["action"]> | null = null, timeout: number = 30000): string | null {
		if (!payload) {
			const error = new Error("Payload is empty!");
			if (callback) {
				callback(error);
			}
			throw error;
		}
		if (!payload.requestId) {
			payload.requestId = globalThis.crypto.randomUUID();
		}
		if (!payload.channel && this.#channel) {
			payload.channel = this.#channel;
		}
		const handlerId = payload.requestId;
		// listen for response
		if (callback) {
			// there was an error in setResponseListener
			this.#setResponseListener(handlerId, callback, timeout, payload.action);
		}
		// If we are not connected, attempt to connect, wait a bit, then send.
		const sendPayload = () => {
			this.off("connected", sendPayload);
			try {
				this.#ws.send(JSON.stringify(payload));

				const msg: MessageTxKnown = {
					dir: "TX",
					error: null,
					payload: payload
				};
				this.emit("message", msg);
			} catch (error) {
				const msg: MessageTxKnown = {
					dir: "TX",
					error: error,
					payload: payload
				};
				this.emit("message", msg);
				this.#clearResponseListener(handlerId);
				if (callback) {
					callback(error);
				}
				throw error;
			}
		};
		if (this.#ws?.readyState === WebSocket.OPEN) {
			sendPayload.apply(this);
		} else {
			this.on("connected", sendPayload);
			this.connected = false;
			this.connect();
		}
		return handlerId;
	}

	#txLogin(): void {
		this.off("message", this.#rxLogin);
		this.on("message", this.#rxLogin);
		this.send(this.#loginPayload);
	}

	#rxLogin(msg: MessageRxKnown | MessageErrorRX): void {
		if (msg.dir !== "RX" || msg.payload?.info !== "login") {
			return;
		}
		msg = structuredClone(msg as MsgResponseLogin); // TODO remove on event messaging rework
		this.connecting = false;
		if (msg.payload.status === "ok") {
			// logged in
			this.connected = true;
			this.user = msg.payload.user; // TODO gupport stuff
			this.data = msg.payload.data; // TODO glient stuff
			this.emit("connected", {
				payload: msg.payload,
				url: this.#url
			});
		} else {
			const msgData: MsgDataWithPayload = {
				reason: "loginFailed",
				payload: msg.payload,
				url: this.#url
			};
			this.disconnect(msgData);
		}
	}

}

export default RocWs;
