import { GridToolbar } from "@mui/x-data-grid";
// import { useTheme } from "@mui/material/styles";
// fonts
import OpenSans_600_Woff2 from "./fonts/OpenSans-600.woff2";
import OpenSans_600_Woff from "./fonts/OpenSans-600.woff";
import OpenSans_600_Italic_Woff2 from "./fonts/OpenSans-600-italic.woff2";
import OpenSans_600_Italic_Woff from "./fonts/OpenSans-600-italic.woff";
import OpenSans_700_Woff2 from "./fonts/OpenSans-700.woff2";
import OpenSans_700_Woff from "./fonts/OpenSans-700.woff";
import OpenSans_700_Italic_Woff2 from "./fonts/OpenSans-700-italic.woff2";
import OpenSans_700_Italic_Woff from "./fonts/OpenSans-700-italic.woff";
// types
import type { ThemeOptions } from "@mui/material/styles";

// import all images
const imageCache = new Map<string, string>();

export const importAll = (requireContext: __WebpackModuleApi.RequireContext) => {
	requireContext.keys().forEach((key) => {
		imageCache.set(key, requireContext(key));
	});
};
// export const importAll = (requireContextTheme: __WebpackModuleApi.RequireContext, requireContextCore: __WebpackModuleApi.RequireContext) => {
// 	requireContextTheme.keys().forEach((key) => {
// 		imageCache.set(key, requireContextTheme(key));
// 	});
// 	requireContextCore.keys().forEach((key) => {
// 		if (!imageCache.has(key)) {
// 			imageCache.set(key, requireContextCore(key));
// 		}
// 	});
// };

export const themeOptions: ThemeOptions = {
	typography: {
		fontFamily: "OpenSans",
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-face {
					font-family: "OpenSans";
					font-style: normal;
					font-weight: 400 600;
					src: url(${OpenSans_600_Woff2 as string}) format("woff2"), url(${OpenSans_600_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: italic;
					font-weight: 400 600;
					src: url(${OpenSans_600_Italic_Woff2 as string}) format("woff2"), url(${OpenSans_600_Italic_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: normal;
					font-weight: 700;
					src: url(${OpenSans_700_Woff2 as string}) format("woff2"), url(${OpenSans_700_Woff as string}) format("woff");
				},
				@font-face {
					font-family: "OpenSans";
					font-style: italic;
					font-weight: 700;
					src: url(${OpenSans_700_Italic_Woff2 as string}) format("woff2"), url(${OpenSans_700_Italic_Woff as string}) format("woff");
				}
			`
		},
		MuiFormControl: {
			defaultProps: {
				variant: "standard"
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: "large"
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "unset"
				}
			}
		},
		MuiSelect: {
			defaultProps: {
				variant: "standard"
			}
		},
		MuiSkeleton: {
			defaultProps: {
				animation: "wave"
			}
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "center"
				}
			}
		},
		MuiTabs: {
			defaultProps: {
				variant: "fullWidth"
			},
			styleOverrides: {
				indicator: {
					display: "none"
				}
			}
		},
		// MuiTab: {
		// 	styleOverrides: {
		// 		root: {
		// 			"color": defaultTheme.palette.primary.main,
		// 			"&.Mui-selected": {
		// 				color: defaultTheme.palette.primary.contrastText,
		// 				backgroundColor: defaultTheme.palette.primary.main
		// 			}
		// 		}
		// 	}
		// },
		MuiTabPanel: {
			styleOverrides: {
				root: {
					padding: 0
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 600
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: "standard"
			}
		},
		MuiDataGrid: {
			defaultProps: {
				checkboxSelection: true,
				disableRowSelectionOnClick: true,
				autoHeight: true,
				components: {
					Toolbar: GridToolbar
				}
			},
			styleOverrides: {
				root: {
					border: "none"
				}
			}
		}
	}
} as const;

// resolve relative image path to image url
// ex: images("devices/di_camera_a.svg")
export const images = (path: string): string | undefined => {
	// const theme = useTheme();

	// ignore console errors when running tests
	if (process.env.NODE_ENV === "test") {
		return "";
	}

	// const themeStyle = (theme.palette.mode === "light") ? "generic" : theme.palette.mode;
	// const fullPath = imageCache.has(`./${themeStyle}/${path}`) ? `./${themeStyle}/${path}` : `./generic/${path}`;
	// const image = imageCache.get(fullPath);
	const image = imageCache.get(`./${path}`);
	if (image === undefined) {
		console.error(`could not find image ${path}`);
		// throw new Error(`could not find image ${path}`);
	}
	return image;
};

// TODO: remove
export const hasImage = (path: string): boolean => (imageCache.has(`./${path}`));
// export const hasImage = (path: string): boolean => {
// 	const theme = useTheme();

// 	const themeStyle = (theme.palette.mode === "light") ? "generic" : theme.palette.mode;
// 	return imageCache.has(`./${themeStyle}/${path}`) || imageCache.has(`./generic/${path}`);
// };
