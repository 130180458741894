import PropTypes from "prop-types";
import { Card, CardHeader, CardContent } from "@mui/material";
import Base from "../../base";
import Svg from "../cmp/svg";

type Props = {
	title: string;
	error: Error;
};

const ErrorPage = (props: Props) => (
	<Base>
		<Card>
			<CardHeader
				title="Error"
				subtitle={props.title}
				avatar={<Svg src="messages/error.svg" />}
			/>
			<CardContent>
				{props.error.message}
			</CardContent>
		</Card>
	</Base>
);

ErrorPage.propTypes = {
	title: PropTypes.string.isRequired,
	error: PropTypes.shape({
		message: PropTypes.string.isRequired
	}).isRequired
};

export default ErrorPage;

